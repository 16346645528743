@import "../../sass/config";
@import "../../sass/mixins";
@import "../../sass/breakpoints";

.container--loading {
    height: 100vh;
    @include flex();

    #logo {
        width: 180px;
    }
}