@import "../../sass/config";
@import "../../sass/mixins";
@import "../../sass/breakpoints";

.testimonials {
  .section__title {
    padding-top: $section-top;
  }

  &__content {
    @include flex($justify: flex-start, $align: flex-start);
    gap: 4.8rem;
    padding-bottom: $section-bottom-big;

    @include media("<tablet") {
      height: auto;
      flex-direction: column;
    }

    &__name {
      @include flex($direction: column);
      gap: $padding-xs;

      .card {
        @include flex($justify: flex-start);
        gap: 1.2rem;
        padding-left: 1.2rem;
        width: 240.467px;
        height: 92px;
        background-color: $blue-alt2;
        border-radius: 14px;
        cursor: pointer;

        i {
          color: $blue-alt3;
          padding: 0.8rem;
          border-radius: 50px;
        }

        h4 {
          @include title-xs();
          font-weight: lighter;
          margin-bottom: 0.4rem;
        }

        p {
          @include text-xs();
        }
      }

      .card--selected,
      .card:hover {
        background-color: $blue-alt1;
        box-shadow: $box-shadow-small;

        i {
          color: $purple;
          background-color: $blue;
        }

        h4 {
          color: $white;
        }
      }
    }

    &__text {
      @include flex($direction: column, $align: flex-start);
      gap: $padding-xs;

      h4 {
        @include text-highlight($color: $white);
      }

      p {
        max-width: 600px;
        @include text-regular();
      }
    }
  }
}
