@import "../../../sass/config";
@import "../../../sass/mixins";
@import "../../../sass/breakpoints";

.zenx {
  background-color: #202020;

  .study-text {
    color: #8c8c8c;
  }

  .zenx--img {
    background-color: #181818;
    @include bg($url: "../../../images/zenx_cover.png", $position: bottom);
  }
}

.twd {
  background-color: #fafafa;

  .study-text {
    color: #1c1c1c;
  }

  .twd--img {
    background-color: #ffffff;
    @include bg($url: "../../../images/twd_cover.png", $position: bottom);
  }
}

.biogeek {
  background-color: #1a1a1a;

  .study-text {
    color: #8c8c8c;
  }

  .biogeek--img {
    background-color: #f8f8f8;
    @include bg($url: "../../../images/biogeek_cover.png", $position: bottom);
  }
}
