@import "../../sass/config";
@import "../../sass/mixins";
@import "../../sass/breakpoints";

.navigation {
  padding-top: 3.2rem;

  @include media("<tablet") {
    padding-top: 2rem;
  }

  @include flex($justify: space-between);

  .navbar {
    list-style: none;

    @include flex();
    gap: 4rem;

    li {
      a {
        @include text-nav();
        transition: $tr150;

        &:hover {
          color: $purple;
        }
      }
    }

    .nav {
      @include media("<tablet") {
        display: none;
      }
    }
  }

  .contact {
    a {
      @include button-small();
      display: inline-block;
      background-color: $light-blue;
      border-radius: 8px;
      padding: $button-padding-s;
      position: relative;
      z-index: 1;
      transition: $tr150;

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        outline: 1px solid $light-blue;
        border-radius: 8px;
        top: 6px;
        left: 6px;
        transition: $tr150;
        z-index: -1;

        @include media("<tablet") {
          display: none;
        }
      }

      &:hover::before {
        top: 1px;
        left: 1px;
        width: 95%;
        height: 95%;
        outline: 1px solid $purple;
      }

      &:hover {
        background-color: $purple;
      }
    }
  }
}
