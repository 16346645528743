@import "../../sass/config";
@import "../../sass/mixins";
@import "../../sass/breakpoints";

.projects {
  padding: $section;
  padding-bottom: $section-bottom-big;

  &__cards {
    @include flex($direction: column);
    gap: 4rem;

    &__card {
      @include flex($direction: column, $align: flex-start);
      gap: 1.8rem;
      width: 100%;
      padding: 5.2rem;
      box-shadow: $box-shadow;
      border-radius: 40px;
      overflow: hidden;

      @include media("<tablet") {
        padding: 3.4rem;
      }

      @include media("<phone") {
        border-radius: 28px;
      }

      .cat-icon {
        color: $white;
        font-size: 5.2rem;
      }

      h4 {
        @include title-h4();

        @include media("<tablet") {
          font-size: $h3;
        }
      }

      p {
        @include text-large($color: rgba(255, 255, 255, 0.4));
        position: relative;
        transition: $tr400;

        @include media("<tablet") {
          display: none;
        }

        i {
          position: absolute;
          top: 48%;
          transform: translateY(-50%);
          right: -34px;
          transition: right 400ms ease-in-out;
        }
      }

      &:hover {
        p {
          color: $white;

          i {
            right: -42px;
          }
        }
      }
    }

    &__small {
      width: 100%;
      @include flex($justify: space-between);
      gap: 4rem;

      @include media("<tablet") {
        flex-direction: column;
      }
    }

    .card--case-studies {
      background: rgb(116, 70, 255);
      background: linear-gradient(
        130deg,
        rgba(116, 70, 255, 1) 0%,
        rgba(107, 68, 227, 1) 100%
      );
    }

    .card--development {
      background: rgb(0, 173, 255);
      background: linear-gradient(
        130deg,
        rgba(0, 173, 255, 1) 0%,
        rgba(19, 137, 226, 1) 100%
      );
    }

    .card--design {
      background: rgb(144, 63, 215);
      background: linear-gradient(
        130deg,
        rgba(144, 63, 215, 1) 0%,
        rgba(154, 85, 216, 1) 100%
      );
    }

    .card--other {
      background: rgb(48, 185, 131);
      background: linear-gradient(
        130deg,
        rgba(48, 185, 131, 1) 0%,
        rgba(0, 204, 124, 1) 100%
      );
    }
  }
}

.category-nav {
  @include flex($align: flex-start, $justify: flex-start);
  gap: $padding-xs;
  padding-bottom: $section-bottom;

  @include media("<tablet") {
    flex-wrap: wrap;
  }
}

.category {
  @include button-small();
  display: inline-block;
  border-radius: 8px;
  padding: $button-padding-s;
  position: relative;
  z-index: 1;
  transition: $tr150;

  i {
    margin-left: 16px;
    transition: $tr150;
  }
}

.case-studies {
  background-color: $purple;
  border: 2px solid $purple;

  &:hover {
    background: none;
    color: $purple;

    i {
      color: $purple;
    }
  }
}

.development {
  background-color: $light-blue;
  border: 2px solid $light-blue;

  &:hover {
    background: none;
    color: $light-blue;

    i {
      color: $light-blue;
    }
  }
}

.design {
  background-color: $light-purple;
  border: 2px solid $light-purple;

  &:hover {
    background: none;
    color: $light-purple;

    i {
      color: $light-purple;
    }
  }
}

.other {
  background-color: $green;
  border: 2px solid $green;

  &:hover {
    background: none;
    color: $green;

    i {
      color: $green;
    }
  }
}

.projects-page {
  padding-bottom: $section-bottom;

  .section__title {
    padding-top: $section-top;
    padding-bottom: $padding-m;
  }

  &__cards {
    @include flex($direction: column);
    gap: 4rem;

    .project-card {
      width: 100%;
    }

    &__card {
      @include flex();
      width: 100%;
      box-shadow: $box-shadow;
      border-radius: 40px;

      @include media("<phone") {
        border-radius: 28px;
      }

      .text {
        @include flex($direction: column, $align: flex-start);
        gap: $padding-m;
        width: 50%;
        border-radius: 40px 0 0 40px;
        padding-left: 5.2rem;

        @include media("<tablet") {
          width: 100%;
          padding: 3rem;
        }

        img {
          width: 200px;
        }

        .study-text {
          @include title-small();
          max-width: 300px;
        }

        .dev-title {
          @include title-h3($color: $light-blue);
          max-width: 300px;

          @include media("<580px") {
            font-size: $text-size-l;
          }
        }

        .labels {
          @include flex();
          gap: 0.6rem;
          margin-top: -1.2rem;

          @include media("<tablet") {
            flex-wrap: wrap;
            justify-content: flex-start;
          }

          button {
            @include text-xs();
            background-color: $blue;
            border: none;
            padding: $button-padding-xs;
            border-radius: 4px;
          }
        }

        .dev-text {
          max-width: 400px;
          @include text-regular();
        }

        .links {
          @include flex();
          gap: 1.6rem;

          a {
            @include button-small();
            border-radius: 8px;
          }

          a:nth-child(1) {
            background-color: $light-blue;
            padding: $button-padding-s;
            transition: background 150ms ease-in-out;

            &:hover {
              background-color: $purple;
            }
          }

          a:nth-child(2) {
            font-size: $text-size-l;
            color: $light-text;

            &:hover {
              color: $white;
            }
          }
        }
      }

      .image {
        width: 50%;
        min-height: 390px;
        border-radius: 0 40px 40px 0;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 100%);

        @include media("<tablet") {
          display: none;
        }
      }
    }
  }
}
