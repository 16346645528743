@import "../../sass/config";
@import "../../sass/mixins";
@import "../../sass/breakpoints";

.intro__footer {
  @include flex();
  padding-bottom: $section-bottom;

  .social {
    position: relative;
    width: 100%;

    &__icons {
      @include flex($justify: flex-end);
      gap: 2rem;

      @include media("<tablet") {
        justify-content: flex-start;
      }
    }

    &::before {
      content: "";
      position: absolute;
      width: 5980px;
      height: 2px;
      background-color: $blue-alt1;
      top: 50%;
      left: -5000px;
      transform: translateY(-50%);

      @include media("<1400px") {
        width: 5940px;
      }

      @include media("<1300px") {
        width: 5900px;
      }

      @include media("<1260px") {
        width: 5840px;
      }

      @include media("<1200px") {
        width: 5780px;
      }

      @include media("<1120px") {
        width: 5720px;
      }

      @include media("<1060px") {
        width: 5680px;
      }

      @include media("<tablet") {
        display: none;
      }
    }

    a {
      font-size: $text-size-l;
      color: $light-text;
    }

    .linkedin:hover {
      color: #0a66c2;
    }

    .dribbble:hover {
      color: #ea4c89;
    }

    .github:hover {
      color: #ffffff;
    }
  }
}
