@import "../../sass/config";
@import "../../sass/mixins";
@import "../../sass/breakpoints";

.about {
  .section__title {
    padding-top: $section-top;

    .social {
      @include flex();
      gap: 2rem;

      a {
        font-size: 1.4rem;
        color: $light-text;
      }

      .linkedin:hover {
        color: #0a66c2;
      }

      .dribbble:hover {
        color: #ea4c89;
      }

      .github:hover {
        color: #ffffff;
      }
    }
  }

  &__info {
    @include flex($justify: flex-start);
    gap: 4.8rem;
    padding-bottom: $section-bottom-big;

    @include media("<tablet") {
      flex-direction: column;
      align-items: flex-start;
    }

    &__profile {
      width: 244px;
      height: 380px;
      @include flex($direction: column, $align: flex-start);
      gap: $padding-xs;

      .image {
        width: 100%;
        height: 100%;
        background-color: $blue-alt2;
        border-radius: 14px;
        box-shadow: $box-shadow-small;

        @include bg($url: "../images/profile.png");
      }

      a {
        width: 100%;
        @include flex();
        @include button-regular();
        text-align: center;
        background-color: $blue-alt2;
        box-shadow: $box-shadow-small;
        border-radius: 8px;
        padding: $button-padding-m;

        i {
          color: $white;
          margin-left: 1rem;
        }

        &:hover {
          background-color: $blue;
          box-shadow: none;
        }

        &:hover i {
          color: $purple;
        }
      }
    }

    &__text {
      @include flex($direction: column, $align: flex-start);
      gap: $padding-xs;

      span {
        @include text-highlight();

        i {
          margin-right: 1rem;
        }
      }

      p {
        max-width: 600px;
        @include text-regular();
      }
    }
  }
}
