/**
 * @license
 * MyFonts Webfont Build ID 4207468, 2021-11-08T17:03:56-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: Gilroy-SemiBold by Radomir Tinkov
 * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/semi-bold/
 * Copyright: Copyright � 2016 by Radomir Tinkov. All rights reserved.
 * 
 * 
 * 
 * © 2021 MyFonts Inc
*/

/* @import must be at top of file, otherwise CSS will not work */
@import url("//hello.myfonts.net/count/40336c");

@import url("https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@300;400;500;600;700&display=swap");

@font-face {
  font-family: "Gilroy-SemiBold";
  src: url("../webFonts/GilroySemiBold/font.woff2") format("woff2"),
    url("../webFonts/GilroySemiBold/font.woff") format("woff");
}

@import "normalize";
@import "breakpoints";
@import "mixins";
@import "config";

// ---- CONTAINERS & WRAPPERS ----

.app {
  position: relative;
  margin: 0 auto;
  overflow: hidden;
}

.container {
  width: 100%;
  position: relative;

  &::before,
  &::after {
    content: "";
    width: 96vw;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -10;

    @include media("<tablet") {
      width: 100%;
    }
  }
}

.container--hero::before {
  left: 0;
  background-color: $blue;
  border-radius: 0 0 80px 0;
  box-shadow: $box-shadow;

  @include media("<phone") {
    border-radius: 0 0 40px 0;
  }
}

.container--skills::before {
  right: 0;
  background-color: $blue;
  box-shadow: $box-shadow-right-top;
  border-radius: 80px 0 0 0;
  height: 2200px;

  @include media("<phone") {
    border-radius: 40px 0 0 0;
  }
}

.container--about::before {
  right: 0;
  background-color: $blue;
  z-index: -6;
}

.container--about::after {
  right: 0;
  background-color: $blue-alt1;
  box-shadow: $box-shadow-right-top;
  border-radius: 80px 0 0 0;
  height: 2200px;
  z-index: -5;

  @include media("<phone") {
    border-radius: 40px 0 0 0;
  }
}

.container--testimonials::before {
  right: 0;
  background-color: $blue-alt1;
  z-index: -4;
}

.container--testimonials::after {
  right: 0;
  background-color: $blue-alt2;
  box-shadow: $box-shadow-right-top;
  border-radius: 80px 0 0 0;
  height: 2200px;
  z-index: -3;

  @include media("<phone") {
    border-radius: 40px 0 0 0;
  }
}

.container--footer::before {
  right: 0;
  background-color: $blue-alt2;
  z-index: -3;
}

.container--footer::after {
  left: 0;
  background-color: $blue;
  box-shadow: $box-shadow;
  border-radius: 0 80px 0 0;
  height: 2200px;
  z-index: -2;

  @include media("<phone") {
    border-radius: 0 40px 0 0;
  }
}

.container--contact,
.container--projects-page {
  background-color: $blue;
}

.container--study {
  background-color: $white;
}

.wrapper {
  max-width: 1160px;
  position: relative;
  margin: 0 auto;

  @include media("<desktop") {
    max-width: 84%;
  }
}

.logo {
  display: inline-block;
  width: 60px;
  height: 40px;
  text-indent: -9999px;

  @include bg($url: "../images/logo.svg", $size: contain);
}

.logo-alt {
  @include bg($url: "../images/logo-alt.svg", $size: contain);
}

// ---- INTRO ----

.header {
  width: 100%;
  padding: 5.8rem 0 6.4rem 0;

  @include media("<tablet") {
    padding: 5rem 0;
  }

  @include flex($justify: space-between);

  .intro {
    width: 50%;
    height: 100%;

    @include media("<desktop") {
      width: 70%;
    }

    @include media("<tablet") {
      width: 100%;
    }

    @include flex($align: flex-start, $justify: center, $direction: column);

    &__text {
      @include flex($align: flex-start, $direction: column);
      gap: 2rem;
      margin-bottom: 5rem;

      @include media("<phone") {
        margin-bottom: 3rem;
      }

      h2 {
        @include title-h2();

        span {
          display: inline-block;
        }

        @include media("<tablet") {
          display: none;
        }
      }

      .tablet-title {
        display: none;

        @include media("<tablet") {
          display: flex;
        }

        @include media("<phone") {
          font-size: 3.8em;
          line-height: 4.6rem;
        }
      }

      p {
        @include title-h3($family: $hind, $color: $light-text);
        font-weight: 300;

        @include media("<tablet") {
          display: none;
        }

        span {
          display: inline-block;
        }

        span:nth-child(2) {
          color: $purple;
        }
      }

      .tablet-paragraph {
        display: none;

        @include media("<tablet") {
          display: block;
        }
      }
    }
  }

  .elements {
    width: 50%;

    @include flex($justify: flex-end);

    @include media("<desktop") {
      display: none;
    }
  }

  #elements {
    height: 468px;
  }
}

.cta {
  @include button-regular();
  display: inline-block;
  border-radius: 8px;
  padding: $button-padding-cta;
  position: relative;
  transition: $tr150;
  z-index: 1;

  @include media("<tablet") {
    display: none;
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    top: 8px;
    left: 8px;
    transition: $tr150;
    z-index: -1;

    @include media("<tablet") {
      display: none;
    }
  }

  &:hover::before {
    top: 1px;
    left: 1px;
    width: 95%;
    height: 95%;

    @include media("<tablet") {
      display: none;
    }
  }

  .arrow {
    display: inline-block;
    font-size: 1.1rem;
    margin-left: 14px;
  }
}

.tablet-cta {
  display: none;

  @include media("<tablet") {
    display: block;
  }
}

.projects--cta {
  background-color: $purple;
  margin-bottom: 20px;

  &::before {
    outline: 1px solid $purple;
  }

  &:hover::before {
    outline: 1px solid $light-blue;
  }

  &:hover {
    background-color: $light-blue;
  }
}

.contact--cta {
  background-color: $light-blue;
  margin-bottom: 8px;

  &::before {
    outline: 1px solid $light-blue;
  }

  &:hover::before {
    outline: 1px solid $purple;
  }

  &:hover {
    background-color: $purple;
  }
}

.section__title {
  @include flex($justify: flex-start, $align: flex-start, $direction: row);
  gap: 0.8rem;
  padding-bottom: $section-bottom;

  @include media("<tablet") {
    flex-direction: column;
    gap: 1.4rem;
  }

  .title {
    @include flex($align: flex-start);
    gap: 0.6rem;
  }

  svg {
    width: 8px;
  }

  h3 {
    @include title-h3();
    line-height: 2.4rem;
    margin-right: 4rem;
    margin-top: -0.5rem;

    @include media("<tablet") {
      margin-right: 0;
    }
  }

  p {
    max-width: 680px;
    @include text-regular();
  }
}

.not-found {
  width: 100%;
  height: calc(100vh - 182.4px);
  @include flex($direction: column);

  .error {
    font-family: $gilroy;
    font-size: 14rem;
    color: $blue-alt3;
  }

  p {
    @include text-regular();
    font-size: $h3;
    color: $blue-alt3;
  }

  .home {
    margin-top: 4rem;

    a {
      font-family: $gilroy;
      font-size: $text-size-l;
      letter-spacing: $ls;
      color: $purple;
      transition: $tr150;

      &:hover {
        color: $light-blue;
      }
    }
  }
}
