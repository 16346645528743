@import "../../sass/config";
@import "../../sass/mixins";
@import "../../sass/breakpoints";

.footer {
  .section__title {
    position: relative;
    padding-top: $section-top;
    align-items: center;
    gap: 20rem;

    @include media("<1200px") {
      gap: 10rem;
    }

    @include media("<tablet") {
      gap: 2rem;
      align-items: flex-start;
    }

    &::before {
      content: "";
      position: absolute;
      width: 5980px;
      height: 2px;
      background-color: $blue-alt1;
      top: 100%;
      left: -5000px;
      transform: translateY(-50%);

      @include media("<1400px") {
        width: 5940px;
      }

      @include media("<1300px") {
        width: 5900px;
      }

      @include media("<1260px") {
        width: 5840px;
      }

      @include media("<1200px") {
        width: 5780px;
      }

      @include media("<1120px") {
        width: 5720px;
      }

      @include media("<1060px") {
        width: 5680px;
      }

      @include media("<tablet") {
        display: none;
      }
    }

    .text {
      @include flex($direction: column, $align: flex-start);
      gap: $padding-s;

      p {
        max-width: 400px;
      }
    }
  }

  &__info {
    @include flex($direction: column, $align: flex-start);
    position: relative;
    padding: $section;
    gap: 6.4rem;

    @include media("<tablet") {
      padding-top: 0;
    }

    &__bg {
      display: block;
      position: absolute;
      right: 174px;
      bottom: -80px;
      width: 520px;

      .logo_bg {
        fill: $blue-alt3;
      }

      @include media("<1200px") {
        display: none;
      }
    }

    &__contact {
      @include flex($direction: column, $align: flex-start);
      gap: $padding-m;

      ul {
        list-style: none;
        @include flex($direction: column, $align: flex-start);
        gap: $padding-xs;

        li {
          @include text-small();

          i {
            color: $blue-alt3;
            margin-right: 14px;
            vertical-align: middle;
          }
        }
      }
    }

    &__copy {
      p {
        @include text-xs($color: $blue-alt3);
      }
    }
  }
}
