// ---- COLOR VARIABLES ----
$dark-blue: hsl(209, 45%, 10%);
$blue: hsl(214, 44%, 15%);
$blue-alt1: hsl(214, 44%, 18%);
$blue-alt2: hsl(214, 44%, 21%);
$blue-alt3: hsl(214, 44%, 24%);
$purple: hsl(255, 100%, 64%);
$light-purple: hsl(272, 66%, 55%);
$light-blue: hsl(199, 100%, 50%);
$green: hsl(156, 59%, 46%);
$red: hsl(0, 54%, 42%);
$white: hsl(0, 0%, 100%);
$white-alt: hsl(0, 0%, 98%);

$light-text: rgb(140, 151, 175);
$light-text-alt: rgb(108, 110, 126);
$dark-text: rgba(136, 147, 170, 0.5);
$footer-text: rgba(136, 147, 170, 0.2);
$box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.2);
$box-shadow-right: -3px -3px 20px rgba(0, 0, 0, 0.2);
$box-shadow-right-top: 0px -3px 20px rgba(0, 0, 0, 0.2);
$box-shadow-small: 0px 0px 8px rgba(0, 0, 0, 0.08);

// ---- FONT VARIABLES ----
$gilroy: "Gilroy-SemiBold";
$hind: "Hind Siliguri", sans-serif;
$light-font: 400;
$regular-font: 500;
$bold-font: 700;
$h1: 5rem;
$h2: 4.2rem;
$h3: 2rem;
$h4: 3.2rem;
$text-size-xs: 0.84rem;
$text-size-s: 1rem;
$text-size-m: 1.2rem;
$text-size-l: 1.4rem;
$ls: 0.5px;
$ls-l: 1px;
$lh: 2.2rem;

// ---- MARGIN & PADDING VARIABLES ----
$header: 8rem 0 10rem 0;
$section: 6.4rem 0;
$section-bottom: 6.4rem;
$section-bottom-big: 12.8rem;
$section-top: 6.4rem;
$padding-xl: 6.4rem;
$padding-m: 2.2rem;
$padding-s: 1.5rem;
$padding-xs: 1rem;
$button-padding-cta: 1rem 2.6rem 1rem 2rem;
$button-padding-m: 1rem 2rem;
$button-padding-s: 0.56rem 1.2rem;
$button-padding-xs: 0.4rem 0.6rem;

// ---- TRANSITION VARIABLES ----
$tr150: all 150ms ease-in-out;
$tr400: all 400ms ease-in-out;

// ---- MIN COLUMN SIZE ----
$col: 346px;
