// ---- TEXT STYLING ----
@mixin title-h2($color: $white) {
  font-family: $gilroy;
  font-size: $h2;
  color: $color;
  letter-spacing: $ls;
  line-height: 5.8rem;
}

@mixin title-h3($family: $gilroy, $color: $white) {
  font-family: $family;
  font-size: $h3;
  color: $color;
  letter-spacing: $ls-l;
  line-height: 2.8rem;
}

@mixin title-h4($color: $white) {
  font-family: $gilroy;
  font-size: $h4;
  color: $color;
}

@mixin title-small() {
  font-family: $gilroy;
  font-size: $text-size-l;
  line-height: 2rem;
}

@mixin title-xs($color: $light-text) {
  font-family: $gilroy;
  color: $color;
}

@mixin purple-heading($color: $purple, $weight: lighter) {
  font-family: $gilroy;
  font-size: $text-size-l;
  font-weight: $weight;
  color: $color;
}

@mixin text-nav() {
  font-family: $gilroy;
  font-size: $text-size-s;
  color: $light-text;
  letter-spacing: $ls;
}

@mixin text-large($color: $light-text) {
  font-family: $hind;
  font-size: $text-size-l;
  color: $color;
}

@mixin text-regular($color: $light-text) {
  font-family: $hind;
  font-size: $text-size-m;
  font-weight: 400;
  line-height: 1.92rem;
  color: $color;
}

@mixin text-small($color: $light-text) {
  font-family: $hind;
  font-size: $text-size-s;
  color: $color;
}

@mixin text-highlight($color: $purple) {
  font-family: $gilroy;
  font-size: $text-size-m;
  color: $color;
}

@mixin text-xs($color: $light-text) {
  font-family: $hind;
  font-size: $text-size-xs;
  color: $color;
}

@mixin button-regular($color: $white) {
  font-family: $gilroy;
  font-size: $text-size-m;
  color: $color;
  letter-spacing: $ls;
}

@mixin button-small($color: $white) {
  font-family: $gilroy;
  font-size: $text-size-s;
  color: $color;
  letter-spacing: $ls;
}

// ---- BACKGROUND STYLING ----
@mixin bg($url, $size: cover, $position: center center) {
  background-image: url($url);
  background-size: $size;
  background-position: $position;
  background-repeat: no-repeat;
}

@mixin bg-settings($size: cover, $position: center center) {
  background-size: $size;
  background-position: $position;
  background-repeat: no-repeat;
}

// ---- POSITIONING ----
@mixin flex($direction: row, $justify: center, $align: center) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
}
