@import "../../sass/config";
@import "../../sass/mixins";
@import "../../sass/breakpoints";

.talk {
  @include flex($align: flex-start);
  padding: $section;

  @include media("<tablet") {
    flex-direction: column;
    gap: 6.4rem;
  }

  &__info {
    position: relative;
    @include flex($direction: column, $justify: flex-start, $align: flex-start);
    gap: 3.5rem;
    width: 30%;

    @include media("<tablet") {
      width: 100%;
    }

    &::before {
      content: "";
      position: absolute;
      width: 96%;
      height: 2px;
      background-color: $blue-alt1;
      top: 57%;
      left: 0;
      transform: translateY(-50%);

      @include media("<tablet") {
        top: 45%;
      }

      @include media("<680px") {
        top: 57%;
      }
    }

    &__title {
      p {
        @include title-h3();
      }
    }

    &__contact {
      @include flex($direction: column, $align: flex-start);
      gap: 2.2rem;

      ul {
        list-style: none;
        @include flex($direction: column, $align: flex-start);
        gap: $padding-xs;

        li {
          @include text-small();

          i {
            color: $blue-alt3;
            margin-right: 14px;
            vertical-align: middle;
          }
        }
      }
    }
  }

  &__form {
    width: 70%;
    margin-left: $padding-xl;
    padding: 4rem;
    background-color: $blue-alt1;
    border-radius: 40px;

    @include media("<tablet") {
      width: 100%;
      margin-left: 0;
    }

    @include media("<phone") {
      padding: 4rem 3rem;
      border-radius: 28px;
    }

    .message-success,
    .message-error {
      padding: 1rem 2rem;
      border-radius: 8px;
      margin-bottom: 3rem;

      p {
        @include text-regular($color: $white);
      }
    }

    .message-success {
      background-color: $purple;
    }

    .message-error {
      background-color: $red;
    }

    .form {
      @include flex(
        $direction: column,
        $justify: flex-start,
        $align: flex-start
      );
      gap: 3.2rem;

      &__group {
        position: relative;
        width: 100%;

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          box-shadow: 0 0 0 100px $blue inset;
          -webkit-box-shadow: 0 0 0 100px $blue inset;
          -webkit-text-fill-color: $white;
        }

        input[type="text"],
        input[type="email"],
        textarea {
          @include text-regular($color: $white);
          width: 100%;
          background: none;
          border: none;
          outline: none;
          border-bottom: 1px solid $blue-alt3;
          padding-left: 5px;
          transition: $tr150;

          &:focus {
            border-bottom: 1px solid $light-blue;
          }
        }

        input {
          height: 68px;
        }

        textarea {
          width: 100%;
          margin-top: 1.5rem;
          resize: none;
        }

        ::placeholder {
          color: $light-text;
          opacity: 1;
        }
      }

      label {
        @include text-xs($color: $light-blue);
        position: absolute;
        top: -15px;
        left: 5px;
        width: 100%;
        opacity: 0;
        transition: $tr150;
      }

      &__submit {
        button {
          @include button-small();
          width: auto;
          height: auto;
          display: inline-block;
          border: none;
          outline: none;
          background-color: $light-blue;
          border-radius: 8px;
          padding: 1rem 1.6rem 1rem 1.6rem;
          position: relative;
          z-index: 1;
          transition: $tr150;
          margin-top: 1.5rem;
          cursor: pointer;

          &:hover {
            background-color: $purple;
          }
        }
      }
    }
  }
}
