@import "../../sass/config";
@import "../../sass/mixins";
@import "../../sass/breakpoints";

.close {
  a {
    @include button-small($color: $light-blue);
    display: inline-block;
    border: 2px solid $light-blue;
    border-radius: 8px;
    padding: $button-padding-s;
    position: relative;
    z-index: 1;
    transition: $tr150;

    &:hover {
      color: $white;
      background-color: $light-blue;
    }

    i {
        vertical-align: middle;
        margin-left: 8px;
    }
  }
}
