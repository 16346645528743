@import "../../../sass/config";
@import "../../../sass/mixins";
@import "../../../sass/breakpoints";

.study {
  // ======== SECTION ========

  .section {
    @include flex($align: flex-start);
    gap: $padding-xl;
    padding: $section;

    @include media("<tablet") {
      flex-direction: column;
      gap: 3rem;
    }

    .bold {
      font-weight: 600;
    }
  }

  .section-space {
    padding: $padding-m;
  }

  // ======== INTRO ========

  .intro {
    gap: $padding-m;
    padding-bottom: 0;

    @include media("<tablet") {
      flex-direction: column;
      gap: 6.4rem;
    }
  }

  // ======== TITLE ========

  .title {
    @include flex($direction: column, $align: flex-start);
    gap: $padding-xs;
    width: 40%;

    @include media("<tablet") {
      width: 100%;
    }

    h2 {
      font-size: $h2;
      color: $blue;

      span {
        color: $purple;
      }
    }

    h3 {
      @include title-h3($color: $light-text-alt);
      max-width: 400px;
    }
  }

  // ======== STEP ========

  .step {
    @include flex();
    @include button-small();
    min-width: 88px;
    min-height: 35px;
    background-color: $light-text-alt;
    border-radius: 80px;
  }

  // ======== TEXT ========

  .text {
    @include flex($direction: column, $align: flex-start);
    gap: $padding-s;
    width: 60%;

    @include media("<tablet") {
      width: 100%;
    }

    h4 {
      @include purple-heading($weight: bold);
    }

    h4:nth-child(2) {
      font-size: $h3;
      color: $blue;
      margin-top: -0.5rem;
    }

    p {
      @include text-regular($color: $light-text-alt);
    }

    ul {
      list-style: none;
      @include flex($direction: column, $align: flex-start);
      gap: 0.5rem;
      padding-left: $padding-xs;
    }

    li {
      @include text-regular();
    }

    li::before {
      content: "\2022";
      color: $purple;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  // ======== ICON ========

  .icon {
    @include flex($direction: column);
    gap: 2rem;
    width: 20%;
    padding-top: 6rem;

    @include media("<tablet") {
      display: none;
    }
  }

  // ======== IMAGES ========

  img {
    border-radius: 28px;
  }

  .wide-image img {
    width: 100%;
    margin: $section;
  }

  .wide-image-s img {
    width: 100%;
    margin-bottom: $section-bottom;
  }

  // ======== LIST ========

  .list {
    @include flex($direction: column, $align: flex-start);
    gap: $padding-s;
    width: 40%;

    @include media("<tablet") {
      width: 100%;
    }

    h4 {
      @include purple-heading($weight: bold);
    }

    ul {
      list-style: none;
      @include flex($direction: column, $align: flex-start);
      gap: 0.5rem;
      padding-left: $padding-xs;
    }

    li {
      @include text-regular($color: $light-text-alt);
    }

    li::before {
      content: "\2022";
      color: $purple;
      font-weight: bold;
      display: inline-block;
      width: 1em;
      margin-left: -1em;
    }
  }

  // ======== ROLE ========

  .role {
    background-color: $white-alt;
    padding: 5rem;
    border-radius: 28px;
    margin-top: $padding-xl;

    @include media("<tablet") {
      padding: 3rem;
    }
  }

  // ======== GOALS ========

  .goals {
    li {
      color: $light-text-alt;
    }
  }

  // ======== SWOT ========

  .swot {
    padding-top: 0;

    li {
      color: $light-text-alt;
    }
  }

  // ======== USER ========

  .user {
    padding-top: 0;

    .icon {
      padding-top: 6.5rem;
    }
  }

  // ======== PERSONA ========

  .persona {
    @include flex($direction: column);
    gap: 3rem;
    background-color: $white-alt;
    padding: 5rem;
    border-radius: 28px;

    @include media("<tablet") {
      padding: 3rem;
    }

    .row {
      width: 100%;
      @include flex($align: flex-start);
      gap: $padding-xl;

      @include media("<tablet") {
        flex-direction: column;
        gap: 3rem;
      }
    }

    .text {
      h4 {
        font-size: $h3;
        color: $purple;
      }
    }

    .quote {
      width: 40%;

      @include media("<tablet") {
        width: 100%;
      }

      p {
        @include text-regular($color: $light-text-alt);
        font-weight: bolder;
        font-size: 2rem;
        line-height: 2.8rem;
      }
    }

    .list {
      width: 50%;

      @include media("<tablet") {
        width: 100%;
      }

      i {
        margin-right: 1rem;
      }
    }
  }

  .journey .icon {
    padding-top: 4rem;
  }

  // ======== WIREFRAME ========

  .wireframe {
    padding-bottom: 0;

    .bar1,
    .bar2 {
      width: 180px;
      height: 100px;
      border-radius: 14px;
    }

    .bar1 {
      background-color: $light-text-alt;
    }

    .bar2 {
      background-color: $purple;
    }

    .icon {
      padding-top: 6.5rem;
    }
  }

  // ======== BRANDING ========

  .branding {
    padding: 0;
    padding-bottom: $section-bottom;

    img:nth-child(4) {
      width: 100%;
      margin: 3rem 0;
    }

    img:nth-child(6) {
      width: 60%;
      margin-top: 3rem;
    }
  }

  // ======== SOLUTION ========

  .solution {
    padding: 0;

    .icon {
      padding-top: 11rem;
    }
  }

  .small-image {
    @include flex($justify: center);
    padding-top: 0;
    padding-bottom: $section-bottom;

    .image {
      @include flex($direction: column);
      gap: 2.5rem;
      width: 60%;

      @include media("<tablet") {
        width: 100%;
      }

      img {
        width: 100%;
        border-radius: 0;
        margin: 0 auto;
      }

      p {
        width: 88%;
        @include text-regular($color: $light-text-alt);
        font-weight: 600;
        text-align: center;
      }
    }
  }

  // ======== Strategy ========

  .strategy {
    padding: 0;
    padding-bottom: $section-bottom;

    img {
      width: 100%;
      border-radius: 0;
      margin: 3rem 0;
    }
  }

  // ======== CONCLUSION ========

  .conclusion {
    padding-top: 0;

    .thanks {
      font-size: $text-size-l;
      font-weight: 600;
      color: $blue;
      margin-bottom: 3rem;
    }
  }
}
